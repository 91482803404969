import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "support--feedback"
    }}>{`Support `}{`&`}{` Feedback`}</h1>
    <h2 {...{
      "id": "support"
    }}>{`Support`}</h2>
    <p>{`Our Community Enablement Manager and Customer Success team are dedicated to providing
you with personalized support. If you have questions or need support with any aspect
of our product, you can reach our support team at:
`}<a parentName="p" {...{
        "href": "mailto:support@capellaspace.com"
      }}>{`support@capellaspace.com`}</a>{`. You can find additional
information and support in our `}<a parentName="p" {...{
        "href": "https://capellaspace.zendesk.com/hc/en-us"
      }}>{`Knowledge Base`}</a>{`.`}</p>
    <h2 {...{
      "id": "feedback"
    }}>{`Feedback`}</h2>
    <p>{`We would greatly appreciate and value your feedback on our products. We may occasionally
reach out to better understand how you are using Capella products and what we might
change to enhance your experience with Capella products. In the meantime, if you find
a bug or would like to suggest a new product feature, you can also send a request to:
`}<a parentName="p" {...{
        "href": "mailto:support@capellaspace.com"
      }}>{`support@capellaspace.com`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      